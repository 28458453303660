$primary: #FF9600;
$dark: #1c1c1e;
$enable-cssgrid: true;
@import "node_modules/bootstrap/scss/bootstrap";
@import "/node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');

body {font-family: "Lexend", Arial, sans-serif;}
h2 {font-family: "Lexend", Arial, sans-serif; font-size: 2em; font-weight: 700;}
h3 {font-family: "Lexend", Arial, sans-serif; font-size: 1.6em;}
h2 + p {font-size: 1.2em;}
p, label, li {font-size: 1em;}
hr:not([size]) {height: 3px; background-color: $dark; opacity: 1;}

.btn-primary {color: white; font-family: "Lexend", Arial, serif; font-size: 1em; border-radius: 1rem; padding: .7rem 2.5rem; box-shadow: 0 6px 20px #00000020;}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus {color: white;}
.btn-sm {padding: .7rem 1.2rem;}
.fs-7 {font-size: .9em;}

.rotate-180 {transform: rotate(180deg);}
.list-supported, .list-unsupported {list-style: none; padding-left: 0; margin-bottom: 0;}
.list-supported li, .list-unsupported li {background-repeat: no-repeat; background-position-y: center; padding-left: 3rem; margin-bottom: .3rem; background-image: url("/img/list-item-supported.webp"); padding-top: .3rem; padding-bottom: .3rem}
.list-supported li {background-image: url("/img/list-item-supported.webp");}
.list-unsupported li {background-image: url("/img/list-item-unsupported.webp");}
.form-check {padding-left: 2rem;}
.form-check .form-check-input {width: 20px; height: 20px; margin-left: -2rem;}
.form-control[type=file] {border: 0; padding-bottom: 30px; padding-left: 30px; margin-left: -18px; box-shadow: none;}
.form-control::file-selector-button {color: white; background: #ee7f00; border: 0; border-radius: 1rem; padding: 0.7rem 2.5rem; font-family: "Lexend", Arial, sans-serif; box-shadow: 0 6px 20px #00000020;}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {background: #f19226;}

@include media-breakpoint-up(sm) {
    h2 {font-size: 2.5em;}
    h3 {font-size: 1.8em;}
    h2 + p {font-size: 1.4em;}
    p, label, li {font-size: 1.2em;}

    .list-supported li, .list-unsupported li {padding-top: 0; padding-bottom: 0}
}
@include media-breakpoint-up(md) {
    .border-md-end {border-right: 1px solid #dee2e6;}
}
@include media-breakpoint-up(lg) {
    .border-lg-end {border-right: 1px solid #dee2e6;}
}
@include media-breakpoint-up(xl) {
    .back-printing-grid {grid-template-rows: auto 1fr;}
    .back-printing-grid > div:nth-of-type(1) {grid-column-start: 1; grid-column-end: 6; grid-row-start: 1; grid-row-end: 2;}
    .back-printing-grid > div:nth-of-type(2) {grid-column-start: 6; grid-column-end: 14; grid-row-start: 1; grid-row-end: 3;}
    .back-printing-grid > div:nth-of-type(3) {grid-column-start: 1; grid-column-end: 6; grid-row-start: 2; grid-row-end: 3;}
}
@include media-breakpoint-up(xxl) {
    h2 {font-size: 3em;}
    h3 {font-size: 2em;}
    h2 + p {font-size: 1.5em;}
    p, label, li {font-size: 1.3em;}

    .form-check {padding-left: 2.2rem;}
    .form-check .form-check-input {width: 25px; height: 25px; margin-left: -2.2rem;}
}
